import React from "react";
import { PageProps } from "gatsby";

const NotFoundPage: React.FC<PageProps> = () => {
  return <>Not Found!</>;
};

NotFoundPage.displayName = "NotFoundPage";

export default NotFoundPage;
